import auth from "@/middleware/auth";

const landingIndex = () =>
  import("../views/Landing/index" /* webpackChunkName: "LandIndex" */);
const landingAindex = () =>
  import("../views/Landing/aLand/index" /* webpackChunkName: "aLandIndex" */);
const landingAview = () =>
  import("../views/Landing/aLand/view" /* webpackChunkName: "aLandView" */);
const landingAedit = () =>
  import("../views/Landing/aLand/edit" /* webpackChunkName: "aLandEdit" */);
const landingApower = () =>
  import("../views/Landing/aLand/power" /* webpackChunkName: "aLandPower" */);

const landingBindex = () =>
  import("../views/Landing/bLand/index" /* webpackChunkName: "bLandIndex" */);
const landingBview = () =>
  import("../views/Landing/bLand/view" /* webpackChunkName: "bLandView" */);
const landingBedit = () =>
  import("../views/Landing/bLand/edit" /* webpackChunkName: "bLandEdit" */);
const landingBpower = () =>
  import("../views/Landing/bLand/power" /* webpackChunkName: "bLandPower" */);

const landingCindex = () =>
  import("../views/Landing/cLand/index" /* webpackChunkName: "cLandIndex" */);
const landingCview = () =>
  import("../views/Landing/cLand/view" /* webpackChunkName: "cLandView" */);
const landingCedit = () =>
  import("../views/Landing/cLand/edit" /* webpackChunkName: "cLandEdit" */);
const landingCpower = () =>
  import("../views/Landing/cLand/power" /* webpackChunkName: "cLandPower" */);

const landingDindex = () =>
  import("../views/Landing/dLand/index" /* webpackChunkName: "dLandIndex" */);
const landingDview = () =>
  import("../views/Landing/dLand/view" /* webpackChunkName: "dLandView" */);
const landingDedit = () =>
  import("../views/Landing/dLand/edit" /* webpackChunkName: "dLandEdit" */);
const landingDpower = () =>
  import("../views/Landing/dLand/power" /* webpackChunkName: "dLandPower" */);

const landingEindex = () =>
  import("../views/Landing/eLand/index" /* webpackChunkName: "eLandIndex" */);
const landingEview = () =>
  import("../views/Landing/eLand/view" /* webpackChunkName: "eLandView" */);
const landingEedit = () =>
  import("../views/Landing/eLand/edit" /* webpackChunkName: "eLandEdit" */);
const landingEpower = () =>
  import("../views/Landing/eLand/power" /* webpackChunkName: "eLandPower" */);

const landingHindex = () =>
  import("../views/Landing/hLand/index" /* webpackChunkName: "hLandIndex" */);
const landingHview = () =>
  import("../views/Landing/hLand/view" /* webpackChunkName: "hLandView" */);
const landingHedit = () =>
  import("../views/Landing/hLand/edit" /* webpackChunkName: "hLandEdit" */);
const landingHpower = () =>
  import("../views/Landing/hLand/power" /* webpackChunkName: "hLandPower" */);

const landingIindex = () =>
  import("../views/Landing/iLand/index" /* webpackChunkName: "iLandIndex" */);
const landingIview = () =>
  import("../views/Landing/iLand/view" /* webpackChunkName: "iLandView" */);
const landingIedit = () =>
  import("../views/Landing/iLand/edit" /* webpackChunkName: "iLandEdit" */);
const landingIpower = () =>
  import("../views/Landing/iLand/power" /* webpackChunkName: "iLandPower" */);

const landingJindex = () =>
  import("../views/Landing/jLand/index" /* webpackChunkName: "jLandIndex" */);
const landingJview = () =>
  import("../views/Landing/jLand/view" /* webpackChunkName: "jLandView" */);
const landingJedit = () =>
  import("../views/Landing/jLand/edit" /* webpackChunkName: "jLandEdit" */);
const landingJpower = () =>
  import("../views/Landing/jLand/power" /* webpackChunkName: "jLandPower" */);

const landingKindex = () =>
  import("../views/Landing/kLand/index" /* webpackChunkName: "kLandIndex" */);
const landingKview = () =>
  import("../views/Landing/kLand/view" /* webpackChunkName: "kLandView" */);
const landingKedit = () =>
  import("../views/Landing/kLand/edit" /* webpackChunkName: "kLandEdit" */);
const landingKpower = () =>
  import("../views/Landing/kLand/power" /* webpackChunkName: "kLandPower" */);

const landingLindex = () =>
  import("../views/Landing/lLand/index" /* webpackChunkName: "lLandIndex" */);
const landingLview = () =>
  import("../views/Landing/lLand/view" /* webpackChunkName: "lLandView" */);
const landingLedit = () =>
  import("../views/Landing/lLand/edit" /* webpackChunkName: "lLandEdit" */);
const landingLpower = () =>
  import("../views/Landing/lLand/power" /* webpackChunkName: "lLandPower" */);

const routes = [
  {
    path: "/landing",
    component: landingIndex,
    name: "landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-a",
    component: landingAindex,
    name: "a-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-a-view/:id",
    component: landingAview,
    name: "a-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-a-edit/:id",
    component: landingAedit,
    name: "a-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-a-power/:id",
    component: landingApower,
    name: "a-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-b",
    component: landingBindex,
    name: "b-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-a-view/:id",
    component: landingBview,
    name: "b-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-b-edit/:id",
    component: landingBedit,
    name: "b-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-b-power/:id",
    component: landingBpower,
    name: "b-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-c",
    component: landingCindex,
    name: "c-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-c-view/:id",
    component: landingCview,
    name: "c-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-c-edit/:id",
    component: landingCedit,
    name: "c-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-c-power/:id",
    component: landingCpower,
    name: "c-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-d",
    component: landingDindex,
    name: "d-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-d-view/:id",
    component: landingDview,
    name: "d-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-d-edit/:id",
    component: landingDedit,
    name: "d-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-d-power/:id",
    component: landingDpower,
    name: "d-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-e",
    component: landingEindex,
    name: "e-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-e-view/:id",
    component: landingEview,
    name: "e-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-e-edit/:id",
    component: landingEedit,
    name: "e-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-e-power/:id",
    component: landingEpower,
    name: "e-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-h",
    component: landingHindex,
    name: "h-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-h-view/:id",
    component: landingHview,
    name: "h-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-h-edit/:id",
    component: landingHedit,
    name: "h-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-h-power/:id",
    component: landingHpower,
    name: "h-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-i",
    component: landingIindex,
    name: "i-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-i-view/:id",
    component: landingIview,
    name: "i-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-i-edit/:id",
    component: landingIedit,
    name: "i-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-i-power/:id",
    component: landingIpower,
    name: "i-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-j",
    component: landingJindex,
    name: "j-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-j-view/:id",
    component: landingJview,
    name: "j-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-j-edit/:id",
    component: landingJedit,
    name: "j-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-j-power/:id",
    component: landingJpower,
    name: "j-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-k",
    component: landingKindex,
    name: "k-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-k-view/:id",
    component: landingKview,
    name: "k-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-k-edit/:id",
    component: landingKedit,
    name: "k-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-k-power/:id",
    component: landingKpower,
    name: "k-landingPower",
    meta: { middleware: [auth] },
  },

  {
    path: "/landing-l",
    component: landingLindex,
    name: "l-landingIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-l-view/:id",
    component: landingLview,
    name: "l-landingView",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-l-edit/:id",
    component: landingLedit,
    name: "l-landingEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/landing-l-power/:id",
    component: landingLpower,
    name: "l-landingPower",
    meta: { middleware: [auth] },
  },
];
export default routes;
