<template>
  <v-container>
    <v-row>
      <v-col col="12">
        <v-footer padless class="font-weight-medium">
          <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }} —
            <strong> © Trusted Accountant</strong>
          </v-col>
        </v-footer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
