import { getError } from "@/utils/helpers";
import AllLandService from "@/services/Land/allLandService";
export const namespaced = true;

export const state = {
  Lands: [],
  loading: false,
  error: null,
  status: null,
};

export const mutations = {
  SET_LANDS(state, Lands) {
    state.Lands = Lands;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getLandsA({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsA()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsB({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsB()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsC({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsC()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsD({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsD()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsE({ commit }) {
    commit("SET_LOADING", true);
    AllLandService.getLandsE()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsH({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsH()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsI({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsI()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsJ({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsJ()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandsK({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsK()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  getLandsL({ commit }) {
    commit("SET_LOADING", true);

    AllLandService.getLandsL()
      .then((response) => {
        commit("SET_LANDS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_LANDS", []);
  },
};
export const getters = {
  Lands: (state) => {
    return state.Lands;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
