import Vue from "vue";
import Vuex from "vuex";
import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User/User";
import * as userSingle from "@/store/modules/User/UserSingle";
import * as team from "@/store/modules/Team/Team";
import * as teamSingle from "@/store/modules/Team/TeamSingle";
import * as colors from "@/store/modules/Maintenance/Color";
import * as clients from "@/store/modules/Client/Client";
import * as clientSingle from "@/store/modules/Client/ClientSingle";
import * as allScan from "@/store/modules/Scans/allScan";
import * as scanSingle from "@/store/modules/Scans/ScanSingle";
import * as clientMessage from "@/store/modules/Messages/clientMessage";
import * as userMessage from "@/store/modules/Messages/userMessage";
import * as changeSingle from "@/store/modules/Change/change";
import * as Lands from "@/store/modules/Land/Lands";
import * as landSingle from "@/store/modules/Land/landSingle";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    user,
    clientMessage,
    userMessage,
    userSingle,
    team,
    teamSingle,
    colors,
    clients,
    clientSingle,
    allScan,
    scanSingle,
    changeSingle,
    Lands,
    landSingle,
  },
});
