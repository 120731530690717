import * as API from "@/services/API";
import * as API2 from "@/services/API2";
export default {
  getChange(clientId) {
    return API.apiClient.get(`/change/${clientId}`);
  },
  postModuleA(clientId, payload) {
    return API.apiClient.put(`/aChange/${clientId}`, payload);
  },
  postModuleB(clientId, payload) {
    return API.apiClient.put(`/bChange/${clientId}`, payload);
  },
  postModuleC(clientId, payload) {
    return API.apiClient.put(`/cChange/${clientId}`, payload);
  },
  postModuleD(clientId, payload) {
    return API.apiClient.put(`/dChange/${clientId}`, payload);
  },
  postModuleE(clientId, payload) {
    return API.apiClient.put(`/eChange/${clientId}`, payload);
  },

  printChangeA(Id) {
    return API2.apiClient.post(`/printChangeA/${Id}`);
  },
  printChangeB(Id) {
    return API2.apiClient.post(`/printChangeB/${Id}`);
  },
  printChangeC(Id) {
    return API2.apiClient.post(`/printChangeC/${Id}`);
  },
  printChangeD(Id) {
    return API2.apiClient.post(`/printChangeD/${Id}`);
  },
  printChangeE(Id) {
    return API2.apiClient.post(`/printChangeE/${Id}`);
  },
};
