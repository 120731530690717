import * as API from "@/services/API";

export default {
  getLandA(clientId) {
    return API.apiClient.get(`/aLand/${clientId}`);
  },
  getLandB(clientId) {
    return API.apiClient.get(`/bLand/${clientId}`);
  },
  getLandC(clientId) {
    return API.apiClient.get(`/cLand/${clientId}`);
  },
  getLandD(clientId) {
    return API.apiClient.get(`/dLand/${clientId}`);
  },
  getLandE(clientId) {
    return API.apiClient.get(`/eLand/${clientId}`);
  },
  getLandH(clientId) {
    return API.apiClient.get(`/hLand/${clientId}`);
  },
  getLandI(clientId) {
    return API.apiClient.get(`/iLand/${clientId}`);
  },
  getLandJ(clientId) {
    return API.apiClient.get(`/jLand/${clientId}`);
  },
  getLandK(clientId) {
    return API.apiClient.get(`/kLand/${clientId}`);
  },
  getLandL(clientId) {
    return API.apiClient.get(`/lLand/${clientId}`);
  },
  getLandsA() {
    return API.apiClient.get(`/aLand/`);
  },
  getLandsB() {
    return API.apiClient.get(`/bLand/`);
  },
  getLandsC() {
    return API.apiClient.get(`/cLand/`);
  },
  getLandsD() {
    return API.apiClient.get(`/dLand/`);
  },
  getLandsE() {
    return API.apiClient.get(`/eLand/`);
  },
  getLandsH() {
    return API.apiClient.get(`/hLand/`);
  },
  getLandsI() {
    return API.apiClient.get(`/iLand/`);
  },
  getLandsJ() {
    return API.apiClient.get(`/jLand/`);
  },
  getLandsK() {
    return API.apiClient.get(`/kLand/`);
  },
  getLandsL() {
    return API.apiClient.get(`/lLand/`);
  },
};
