import Vue from "vue";
import VueI18n from "vue-i18n";
import nl from "vee-validate/dist/locale/nl.json";
import en from "vee-validate/dist/locale/en.json";
import nl2 from "./locales/nl2.json";
import en2 from "./locales/en2.json";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "nl",
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages: {
    nl: {
      login: nl2.login,
      test: nl2.test,
      nav: nl2.nav,
      clients: nl2.clients,
      users: nl2.users,
      crud: nl2.crud,
      team: nl2.team,
      gender: nl2.gender,
      segment: nl2.segment,
      branch: nl2.branch,
      size: nl2.size,
      ownership: nl2.ownership,
      scan: nl2.scan,
      fields: {
        email: "e-mail",
        password: "Wachtwoord",
        code: "Twee factor code",
        teamnaam: "Naam van het team",
        company: "Bedrijfsnaam",
        Bedrijfsnaam: "Bedrijfsnaam",
      },
      validation: nl.messages,
    },
    en: {
      login: en2.login,
      test: en2.test,
      nav: en2.nav,
      clients: en2.clients,
      users: en2.users,
      crud: en2.crud,
      team: en2.team,
      gender: en2.gender,
      segment: en2.segment,
      branch: en2.branch,
      size: en2.size,
      ownership: en2.ownership,
      scan: en2.scan,
      fields: {
        email: "e-mail",
        password: "Password",
        code: "Two factor code",
        teamnaam: "Team name",
        company: "Company",
        Bedrijfsnaam: "Company name",
      },
      validation: en.messages,
    },
  },
});

export { i18n };
