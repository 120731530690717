import auth from "@/middleware/auth";

const changeIndex = () =>
  import("@/views/Change/index" /* webpackChunkName: "changeIndex" */);
const editChangeA = () =>
  import("@/views/Change/editA" /* webpackChunkName: "editChangeA" */);
const editChangeB = () =>
  import("@/views/Change/editB" /* webpackChunkName: "editChangeB" */);
const editChangeC = () =>
  import("@/views/Change/editC" /* webpackChunkName: "editChangeC" */);
const editChangeD = () =>
  import("@/views/Change/editD" /* webpackChunkName: "editChangeD" */);
const editChangeE = () =>
  import("@/views/Change/editE" /* webpackChunkName: "editChangeE" */);
const routes = [
  {
    path: "/change-index/:id",
    component: changeIndex,
    name: "changeIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/change-edit-a/:id",
    component: editChangeA,
    name: "editChangeA",
    meta: { middleware: [auth] },
  },
  {
    path: "/change-edit-b/:id",
    component: editChangeB,
    name: "editChangeB",
    meta: { middleware: [auth] },
  },
  {
    path: "/change-edit-c/:id",
    component: editChangeC,
    name: "editChangeC",
    meta: { middleware: [auth] },
  },
  {
    path: "/change-edit-d/:id",
    component: editChangeD,
    name: "editChangeD",
    meta: { middleware: [auth] },
  },
  {
    path: "/change-edit-e/:id",
    component: editChangeE,
    name: "editChangeE",
    meta: { middleware: [auth] },
  },
];
export default routes;
