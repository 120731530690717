import auth from "@/middleware/auth";

const clientsIndex = () =>
  import("../views/Clients/index" /* webpackChunkName: "clientI" */);
const clientsView = () =>
  import("../views/Clients/view" /* webpackChunkName: "clientV" */);
const clientCreate = () =>
  import("../views/Clients/create" /* webpackChunkName: "clientC" */);
const clientEdit = () =>
  import("../views/Clients/edit" /* webpackChunkName: "clientE" */);

const routes = [
  {
    path: "/clients",
    component: clientsIndex,
    name: "clientsIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/client-view/:id",
    name: "clientView",
    meta: { middleware: [auth] },
    component: clientsView,
  },
  {
    path: "/client-create",
    name: "clientCreate",
    meta: { middleware: [auth] },
    component: clientCreate,
  },
  {
    path: "/client-edit/:id",
    name: "clientEdit",
    meta: { middleware: [auth] },
    component: clientEdit,
  },
];
export default routes;
