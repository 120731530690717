import * as API from "@/services/API";

export default {
  getAllScan(clientId) {
    return API.apiClient.get(`/showAllScan/${clientId}`);
  },
  getScanA(clientId) {
    return API.apiClient.get(`/aScans/${clientId}`);
  },
  getScanB(clientId) {
    return API.apiClient.get(`/bScans/${clientId}`);
  },
  getScanC(clientId) {
    return API.apiClient.get(`/cScans/${clientId}`);
  },
  getScanD(clientId) {
    return API.apiClient.get(`/dScans/${clientId}`);
  },
  getScanE(clientId) {
    return API.apiClient.get(`/eScans/${clientId}`);
  },
  getScanF(clientId) {
    return API.apiClient.get(`/fScans/${clientId}`);
  },
  getScanG(clientId) {
    return API.apiClient.get(`/gScans/${clientId}`);
  },
  getScanH(clientId) {
    return API.apiClient.get(`/hScans/${clientId}`);
  },
  getScanI(clientId) {
    return API.apiClient.get(`/iScans/${clientId}`);
  },
  getScanJ(clientId) {
    return API.apiClient.get(`/jScans/${clientId}`);
  },
  getScanK(clientId) {
    return API.apiClient.get(`/kScans/${clientId}`);
  },
  getScanL(clientId) {
    return API.apiClient.get(`/lScans/${clientId}`);
  },
  getScanM(clientId) {
    return API.apiClient.get(`/mScans/${clientId}`);
  },
  getScanN(clientId) {
    return API.apiClient.get(`/nScans/${clientId}`);
  },
  getScanO(clientId) {
    return API.apiClient.get(`/oScans/${clientId}`);
  },
  getScanP(clientId) {
    return API.apiClient.get(`/pScans/${clientId}`);
  },
};
