import auth from "@/middleware/auth";

const presIndex = () =>
  import("../views/Pres/index" /* webpackChunkName: "presIndex" */);

const routes = [
  {
    path: "/pres-index/:id",
    component: presIndex,
    name: "presIndex",
    meta: { middleware: [auth] },
  },
];
export default routes;
