import * as API from "@/services/API";

export default {
  getClient(clientId) {
    return API.apiClient.get(`/clients/${clientId}`);
  },
  getClients() {
    return API.apiClient.get(`/clients/`);
  },
  postClient(payload) {
    return API.apiClient.post(`/clients/`, payload);
  },
  updateClient(clientId, payload) {
    return API.apiClient.put(`/clients/${clientId}`, payload);
  },
  deleteClient(clientId) {
    return API.apiClient.delete(`/clients/${clientId}`);
  },
  uploadClients(payload) {
    return API.apiClient.post(`/clientsUpload/`, payload.file);
  },
};
