import { getError } from "@/utils/helpers";
import MessageService from "@/services/MessageService";

export const namespaced = true;

export const state = {
  clientMessages: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_CLIENTMESSAGE(state, clientMessages) {
    state.clientMessages = clientMessages;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getClientMessages({ commit }, userId) {
    commit("SET_LOADING", true);

    MessageService.getMessageClient(userId)
      .then((response) => {
        commit("SET_CLIENTMESSAGE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
};
export const getters = {
  clientMessages: (state) => {
    return state.clientMessages;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
