import { getError } from "@/utils/helpers";
import UserService from "@/services/UserService";

export const namespaced = true;

export const state = {
  userSingle: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_userSingle(state, userSingle) {
    state.userSingle = userSingle;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getUser({ commit }, UserId) {
    commit("SET_LOADING", true);

    UserService.getUser(UserId)
      .then((response) => {
        commit("SET_userSingle", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
};
export const getters = {
  userSingle: (state) => {
    return state.userSingle;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
