import auth from "@/middleware/auth";

const pcmIndex = () =>
  import("../views/Pcm/index" /* webpackChunkName: "pcmIndex" */);

const routes = [
  {
    path: "/quick-index/:id",
    component: pcmIndex,
    name: "pcmIndex",
    meta: { middleware: [auth] },
  },
];
export default routes;
