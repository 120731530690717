import { getError } from "@/utils/helpers";
import AllLandService from "@/services/Land/allLandService";

export const namespaced = true;

export const state = {
  landSingle: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_LAND_SINGLE(state, landSingle) {
    state.landSingle = landSingle;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getLandA({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandA(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandB({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandB(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandC({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandC(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandD({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandD(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandE({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandE(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandH({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandH(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandI({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandI(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandJ({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandJ(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandK({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandK(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getLandL({ commit }, ClientId) {
    commit("SET_LOADING", true);

    AllLandService.getLandL(ClientId)
      .then((response) => {
        commit("SET_LAND_SINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_LAND_SINGLE", []);
  },
};
export const getters = {
  landSingle: (state) => {
    return state.landSingle;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
