import { getError } from "@/utils/helpers";
import allScanService from "@/services/Scan/allScanService";

export const namespaced = true;

export const state = {
  ScanSingle: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_SCANSINGLE(state, ScanSingle) {
    state.ScanSingle = ScanSingle;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getScanA({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanA(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanB({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanB(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanC({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanC(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanD({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanD(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanE({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanE(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanF({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanF(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanG({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanG(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanH({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanH(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanI({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanI(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanJ({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanJ(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanK({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanK(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanL({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanL(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getScanM({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanM(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  getScanN({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanN(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  getScanO({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanO(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  getScanP({ commit }, UserId) {
    commit("SET_LOADING", true);
    allScanService
      .getScanP(UserId)
      .then((response) => {
        commit("SET_SCANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_SCANSINGLE", []);
  },
};
export const getters = {
  ScanSingle: (state) => {
    return state.ScanSingle;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
